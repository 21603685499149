
import StickyPage from "@/components/sticky-page.vue";
import StickyCollapse from "@/components/sticky/sticky-collapse.vue";
import { Options, Vue } from "vue-class-component";

// @ is an alias to /src
@Options({
  name: "AboutView",
  components: {
    StickyPage,
    StickyCollapse,
  },
})
export default class AboutView extends Vue {
  public versions = [
    {
      name: "1.2.0 (January 2020-)",
      adds: [
        { name: "settings page" },
        { name: "debug mode" },
        { name: "main menu" },
        { name: "loading spinner" },
        { name: "toast messages" },
      ],
      core: [{ name: "auth refresh" }, { name: "responsive pages" }],
      change: [
        { name: "optional pause on timer" },
        { name: "feature walkthrough changed to getting started in app guide" },
      ],
      remove: [{ name: "footer tabs" }],
      patches: [],
    },
    {
      name: "1.1.0 (January 2020)",
      adds: [],
      core: [{ name: "Third party token exchange" }],
      change: [],
      remove: [],
      patches: [],
    },
    {
      name: "1.0.0 (December 2020)",
      adds: [{ name: "Add about page with debug info" }],
      core: [{ name: "Upgrade to Vue 3" }],
      change: [],
      remove: [{ name: "user profile - temporarily" }],
      patches: [
        {
          name: "1.0.1 (December 2020)",
          adds: [{ name: "Add google to csp for usage data" }],
          core: [],
          change: [
            { name: "Update about page with changelog and policy links" },
          ],
          remove: [],
        },
      ],
    },
  ];

  public get refreshCount() {
    return window.ww.initAllCount || 0;
  }
}
