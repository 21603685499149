<template>
  <div class="sticky-toggle" @click="toggle">
    <i :class="iconClass"><fa :icon="icon" /></i>
    <span class="font-14">{{ label }}</span>
    <i class="float-right"><fa icon="angle-down" /></i>
    <slot v-if="innerValue" />
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { PropType } from 'vue';

export default defineComponent({
  props: {
    initialValue: {
      type: Boolean,
      default: false,
    },
    label: {
      required: true,
      type: String,
      validator: (val: string):boolean => {
        return !!val?.length;
      },
    },
    iconClass: {
      required: false,
      type: String,
      default: "font-14 color-highlight",
    },
    icon: {
      required: false,
      type:  Object as PropType<string | string[]>,
      default: null,
    },
  },
  data() {
    return {
      innerValue: false,
      // label: this.label,
      // iconClass: this.iconClass,
      // icon:this.icon
    };
  },
  emits: ["toggled"],

  created() {
    this.innerValue = (this as any).initialValue as boolean; //HACK: this should work without cast grr.
  },

  methods: {
    toggle() {
      this.innerValue = !this.innerValue;
      this.$emit("toggled", this.innerValue);
    },
  },
});
// .list-custom-small {
//   line-height: 50px;
//   .badge {
//     position: absolute;
//     right: 15px;
//     margin-top: 16px !important;
//     font-size: 9px;
//     padding: 5px 8px 5px 8px;
//     font-weight: 700;
//   }

// .custom-control {
//   z-index: 10;
//   position: absolute;
//   right: 40px;
//   margin-top: -40px;
//   input {
//     transform: translateY(-5px) !important;
//   }
// }
//}
</script>
<style lang="scss" scoped>
.sticky-toggle {
  color: $color_dark;
  font-weight: 500;
  font-size: 13px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  i {
    &:first-child {
      margin-left: 0px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-top: 10px;
      text-align: center;
      float: left;
      margin-right: 10px;
      svg {
        font-size: 14px;
      }
    }
    &:last-child {
      float: right;
      font-size: 10px;
      opacity: 0.5;
      //margin-top: 20px;
    }
    svg {
      line-height: unset;
      height: unset;
    }
  }
}
svg {
  line-height: 50px;
}
</style>