<template>
  <sticky-page>
    <div class="card card-style" data-card-height="400">
      <div class="card-center text-center">
        <h1 class="color-white font-32">Word Warden 1.0</h1>
        <p class="mb-0 color-highlight mb-3 mt-n2">The App for writers.</p>
        <p class="color-white mt-1 boxed-text-xl opacity-70">
          We always appreciate your feedback use the contact page to suggest
          features and review the updates below!
        </p>
      </div>
      <div class="card-overlay bg-black opacity-40"></div>
      <div class="card-overlay-infinite bg-img"></div>
    </div>

    <div class="card card-style">
      <div class="content">
        <h4>Word Warden Version History</h4>
        <p>Click on a version to see details of changes</p>

        <div
          v-for="(version, i) in versions"
          :key="version.name"
          class="list-group list-custom-small list-icon-0 mb-2"
        >
          <sticky-collapse
            icon-class="color-highlight"
            :icon="['far', 'code-merge']"
            :label="version.name"
          >
            <div
              :id="'vcol-' + i"
              class="list-group list-custom-small ls-xs pl-3"
            >
              <template v-if="version.core.length">
                <a v-for="(c, j) in version.core" :key="j">
                  <i class="color-blue2-dark"><fa icon="check" /></i>
                  <span
                    class="badge bg-blue2-dark text-uppercase color-white p-1 mr-2"
                    >Core</span
                  >{{ c.name }}
                </a>
                <div class="mb-4"></div>
              </template>
              <template v-if="version.adds.length">
                <a v-for="(add, j) in version.adds" :key="j">
                  <i class="color-green1-dark"><fa icon="plus" /></i>
                  <span
                    class="badge bg-green1-dark text-uppercase color-white p-1 mr-2"
                    >Added</span
                  >{{ add.name }}
                </a>
                <div class="mb-4"></div>
              </template>
              <template v-if="version.change.length">
                <a v-for="(chg, j) in version.change" :key="j">
                  <i class="color-yellow1-dark"><fa icon="edit" /></i>
                  <span
                    class="badge bg-yellow1-dark text-uppercase color-white p-1 mr-2"
                    >Updated</span
                  >{{ chg.name }}
                </a>
                <div class="mb-4"></div>
              </template>
              <a v-for="(rm, j) in version.remove" :key="j">
                <i class="color-red1-dark"><fa :icon="['far','trash-alt']" /></i>
                <span
                  class="badge bg-red1-dark text-uppercase color-white p-1 mr-2"
                  >Removed</span
                >{{ rm.name }}
              </a>
            </div>
          </sticky-collapse>
        </div>
      </div>
    </div>
    <div class="footer card card-style">
      <a href="#" class="footer-title"
        ><span class="color-highlight">Word Warden</span></a
      >
      <p class="footer-text">
        <span
          >Made with
          <i class="fa fa-heart color-highlight font-16 pl-2 pr-2"
            ><fa icon="heart"
          /></i>
          by Justin</span
        >
        <!-- <br><br>Powered by the best Mobile Website Developer on Envato Market. Elite Quality. Elite Products. -->
      </p>
      <!-- <div class="text-center mb-3">
                <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-facebook"><i class="fab fa-facebook-f"></i></a>
                <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-twitter"><i class="fab fa-twitter"></i></a>
                <a href="#" class="icon icon-xs rounded-sm shadow-l mr-1 bg-phone"><i class="fa fa-phone"></i></a>
                <a href="#" data-menu="menu-share" class="icon icon-xs rounded-sm mr-1 shadow-l bg-red2-dark"><i class="fa fa-share-alt"></i></a>
                <a href="#" class="back-to-top icon icon-xs rounded-sm shadow-l bg-dark1-light"><i class="fa fa-angle-up"></i></a>
            </div> -->
      <p class="footer-copyright">
        Copyright &copy; AdroitCode <span id="copyright-year">2021</span>. All
        Rights Reserved.
      </p>
      <p class="footer-links">
        <router-link to="/privacy" class="color-highlight"
          >Privacy Policy</router-link
        >
        |
        <router-link to="/terms" class="color-highlight"
          >Terms and Conditions</router-link
        >
        | <a href="#" class="back-to-top color-highlight"> Back to Top</a>
      </p>
      <div class="clear"></div>
    </div>
  </sticky-page>
</template>

<script lang="ts">
import StickyPage from "@/components/sticky-page.vue";
import StickyCollapse from "@/components/sticky/sticky-collapse.vue";
import { Options, Vue } from "vue-class-component";

// @ is an alias to /src
@Options({
  name: "AboutView",
  components: {
    StickyPage,
    StickyCollapse,
  },
})
export default class AboutView extends Vue {
  public versions = [
    {
      name: "1.2.0 (January 2020-)",
      adds: [
        { name: "settings page" },
        { name: "debug mode" },
        { name: "main menu" },
        { name: "loading spinner" },
        { name: "toast messages" },
      ],
      core: [{ name: "auth refresh" }, { name: "responsive pages" }],
      change: [
        { name: "optional pause on timer" },
        { name: "feature walkthrough changed to getting started in app guide" },
      ],
      remove: [{ name: "footer tabs" }],
      patches: [],
    },
    {
      name: "1.1.0 (January 2020)",
      adds: [],
      core: [{ name: "Third party token exchange" }],
      change: [],
      remove: [],
      patches: [],
    },
    {
      name: "1.0.0 (December 2020)",
      adds: [{ name: "Add about page with debug info" }],
      core: [{ name: "Upgrade to Vue 3" }],
      change: [],
      remove: [{ name: "user profile - temporarily" }],
      patches: [
        {
          name: "1.0.1 (December 2020)",
          adds: [{ name: "Add google to csp for usage data" }],
          core: [],
          change: [
            { name: "Update about page with changelog and policy links" },
          ],
          remove: [],
        },
      ],
    },
  ];

  public get refreshCount() {
    return window.ww.initAllCount || 0;
  }
}
</script>
<style lang="scss" scoped>
.net {
  float: right;
  opacity: 10;
  font-size: 1rem;
}
.bg-img {
  background-image: url("/images/pictures/_bg-infinite.jpg");
}
.ls-xs {
  a {
    line-height: 35px;
    i {
      &:first-child {
        margin-top: 3px;
      }
    }
    span {
      margin-top: 9px !important;
    }
  }
}
</style>